import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectCreated = ({
  activity: {
    activityId,
    person,
    details: {
      activityType,
      data: { target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_created'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.summary`}
          values={{
            subject: person.displayName,
            package: target.package,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag
          type="default"
          dataTestId={`activity-feed-item-${activityId}-status-tag`}
          dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
        >
          {t(`${activityType}.status`)}
        </Feed.Tag>
        <Link
          {...{
            target: linkTargetResolver.package({ target }),
            t,
            onNavigate,
            currentUrl,
            dataTestId: `activity-feed-item-${activityId}-view-package-link`,
            dataTrackingId: `activity-feed-item-${activityId}-view-package-link`,
          }}
        />
      </Feed.Annotations>
    </>
  )
