import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectServiceAdded = ({
  activity: {
    activityId,
    person,
    details: {
      activityType,
      data: { target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_service_added'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.summary`}
          values={{
            subject: person.displayName,
            project: target.project,
            service: target.service,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Link
          {...{
            target: linkTargetResolver['project-service']({ target }),
            t,
            onNavigate,
            currentUrl,
            dataTestId: `activity-feed-item-${activityId}-view-project-link`,
            dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
          }}
        />
      </Feed.Annotations>
    </>
  )
