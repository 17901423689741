import { SessionId } from '@mntn-dev/domain-types'

import { fromUnauthenticatedSession } from './manager.ts'
import { SystemUser } from './system-session.ts'

export const AuthorizedSystemSession = () =>
  fromUnauthenticatedSession({
    sessionId: SessionId(),
    principal: {
      user: {
        emailAddress: SystemUser.emailAddress,
        userId: SystemUser.userId,
        teamIds: [],
      },
      scopes: [],
    },
    user: SystemUser,
  }).authorize(SystemUser)
