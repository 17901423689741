import type {
  AuthenticatedSession,
  AuthorizedSession,
  AuthorizedSessionJSON,
} from '@mntn-dev/auth-types'
import { TeamId, type UserDomainQueryModel } from '@mntn-dev/domain-types'

import { defined } from '@mntn-dev/utilities'
import { createPermissions } from './permissions/create-permissions.ts'
import type { SystemUser } from './system-session.ts'

export const authorizeSessionFromUser = (
  session: AuthenticatedSession | AuthorizedSession,
  user: UserDomainQueryModel | SystemUser
): AuthorizedSession => {
  const authorizedSession: AuthorizedSession = {
    ...session,
    principal: {
      ...session.principal,
      user: {
        userId: user.userId,
        emailAddress: user.emailAddress,
        userType: user.userType,
        teamIds: [
          ...defined([
            user.userType === 'brand'
              ? TeamId.fromBrandId(user.brand?.brandId)
              : user.userType === 'maker'
                ? TeamId.fromAgencyId(user.agency?.agencyId)
                : undefined,
          ]),
        ],
      },
      ...createPermissions({ userType: user.userType }),
    },
  } as const

  return authorizedSession
}

export const rehydrateAuthorizedSession = (
  session: AuthorizedSession | AuthorizedSessionJSON
): AuthorizedSession => {
  const authorizedSession: AuthorizedSession = {
    ...session,
    principal: {
      ...session.principal,
      ...createPermissions({ userType: session.principal.user.userType }),
    },
  } as const

  return authorizedSession
}
