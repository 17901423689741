import type { ActivityModel } from '@mntn-dev/app-activity'
import type { ActivityDomainQueryModel } from '@mntn-dev/domain-types'
import { userDomainQueryModelToActor } from '~/components/avatar/helper.ts'

export const toActivityModel: (
  activity: ActivityDomainQueryModel
) => ActivityModel = (activity: ActivityDomainQueryModel) => ({
  ...activity,
  person: activity.actor && userDomainQueryModelToActor(activity.actor),
})
