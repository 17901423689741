import { linkTargetResolver } from '@mntn-dev/app-routing'
import { useLocale } from '@mntn-dev/i18n'
import { Feed, Stack } from '@mntn-dev/ui-components'
import { addDays, formatDate } from '@mntn-dev/utilities'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectBiddingOpened = ({
  activity: {
    activityId,
    person,
    details: {
      activityType,
      data: { biddingCloseDate, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_bidding_opened'>) => {
  const locale = useLocale()
  const isBiddingOpen = Date.now() < biddingCloseDate.getTime()
  const biddingStatus = isBiddingOpen ? 'open' : 'closed'
  const isBiddingClosingSoon =
    isBiddingOpen && addDays(Date.now(), 4) > biddingCloseDate

  const i18nPropertyKey = <Property extends 'summary' | 'details' | 'status'>(
    property: Property
  ): `${typeof activityType}.${typeof biddingStatus}.${Property}` =>
    `${activityType}.${biddingStatus}.${property}`

  return (
    person && (
      <>
        <Feed.Summary>
          <Stack direction="col" gap="2">
            <div>
              <ActivityTrans
                t={t}
                i18nKey={i18nPropertyKey('summary')}
                values={{
                  subject: person.displayName,
                  project: target.project,
                }}
              />
            </div>
            <div>
              <ActivityTrans
                t={t}
                i18nKey={i18nPropertyKey('details')}
                values={{
                  biddingCloseDate: formatDate(
                    biddingCloseDate,
                    isBiddingClosingSoon ? 'relative-time' : 'medium-date',
                    locale
                  ),
                }}
              />
            </div>
          </Stack>
        </Feed.Summary>
        <Feed.Annotations>
          <Feed.Tag
            type={isBiddingOpen ? 'info' : 'default'}
            dataTestId={`activity-feed-item-${activityId}-status-tag`}
            dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
          >
            {t(i18nPropertyKey('status'))}
          </Feed.Tag>
          <Link
            {...{
              target: linkTargetResolver.project({ target }),
              t,
              onNavigate,
              currentUrl,
              dataTestId: `activity-feed-item-${activityId}-view-project-link`,
              dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
            }}
          />
        </Feed.Annotations>
      </>
    )
  )
}
