import type { AnyRoute } from '@mntn-dev/app-routing'
import type { ActivityId } from '@mntn-dev/domain-types'
import { useTranslation } from '@mntn-dev/i18n'
import {
  Feed,
  LoadingCenter,
  type TestIds,
  getTestProps,
} from '@mntn-dev/ui-components'
import type { ThemeBackground } from '@mntn-dev/ui-theme'
import type { AbstractImage } from '@mntn-dev/ui-utilities'
import { isNonEmptyArray } from '@mntn-dev/utilities'
import { Activity } from './activity.tsx'
import type { ActivityModel, ActivityVariant } from './types.ts'

type ActivityFeedProps = TestIds & {
  activities: ActivityModel[] | undefined
  backgroundColor?: ThemeBackground
  image: AbstractImage
  onNavigate: (route: AnyRoute) => void
  currentUrl: string
  scrollActivityId?: ActivityId
  variant: ActivityVariant
}

export const ActivityFeed = ({
  activities,
  backgroundColor,
  dataTestId,
  dataTrackingId,
  image,
  onNavigate,
  currentUrl,
  scrollActivityId,
  variant,
}: ActivityFeedProps) => {
  const { t } = useTranslation('feed')

  return activities ? (
    <Feed {...getTestProps({ dataTestId, dataTrackingId })}>
      <Feed.ItemList backgroundColor={backgroundColor}>
        {isNonEmptyArray(activities) ? (
          activities.map((activity) => (
            <Activity
              key={activity.activityId}
              {...{
                activity,
                image,
                onNavigate,
                currentUrl,
                scroll: activity.activityId === scrollActivityId,
                variant,
              }}
            />
          ))
        ) : (
          <Feed.Empty>{t('no-activity')}</Feed.Empty>
        )}
      </Feed.ItemList>
    </Feed>
  ) : (
    <LoadingCenter />
  )
}
