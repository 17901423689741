import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed, Stack } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectBiddingClosed = ({
  activity: {
    activityId,
    person,
    details: {
      activityType,
      data: { bidCount: count, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_bidding_closed'>) => {
  const i18nPropertyKey = <Property extends 'summary' | 'details' | 'status'>(
    property: Property
  ): `${typeof activityType}.${Property}` => `${activityType}.${property}`

  return (
    person && (
      <>
        <Feed.Summary>
          <Stack direction="col" gap="2">
            <div>
              <ActivityTrans
                t={t}
                i18nKey={i18nPropertyKey('summary')}
                values={{
                  subject: person.displayName,
                  project: target.project,
                  count,
                }}
              />
            </div>
            <div>
              <ActivityTrans
                t={t}
                i18nKey={i18nPropertyKey('details')}
                values={{ count }}
              />
            </div>
          </Stack>
        </Feed.Summary>
        <Feed.Annotations>
          <Feed.Tag
            type={count === 0 ? 'default' : 'info'}
            dataTestId={`activity-feed-item-${activityId}-status-tag`}
            dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
          >
            {t(i18nPropertyKey('status'), { count })}
          </Feed.Tag>
          <Link
            {...{
              target: linkTargetResolver.project({ target }),
              t,
              onNavigate,
              currentUrl,
              dataTestId: `activity-feed-item-${activityId}-view-project-link`,
              dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
            }}
          />
        </Feed.Annotations>
      </>
    )
  )
}
