import type { UserDomainQueryModel, UserType } from '@mntn-dev/domain-types'
import {
  type AvatarBorderColor,
  type Person,
  toPerson,
} from '@mntn-dev/ui-components'
import { getFileImageProxyUrl } from '~/utils/client/file-utilities.ts'

export const userDomainQueryModelToActor = (
  model?: UserDomainQueryModel | null
): Person => {
  return toPerson({
    firstName: model?.firstName || '-',
    lastName: model?.lastName || '-',
    avatarFileId: model?.avatarFileId,
    avatarUrl:
      model?.avatarFileId &&
      getFileImageProxyUrl({
        fileId: model.avatarFileId,
        options: { width: 200, height: 200, gravity: 'custom', crop: 'thumb' },
      }),
  })
}

export const userHighlightMap: Record<UserType, AvatarBorderColor> = {
  brand: 'info',
  maker: 'caution',
  internal: 'positive',
}
