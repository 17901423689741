import type { TFunction } from 'i18next'

import type { AnyRoute, LinkTarget, RouteKey } from '@mntn-dev/app-routing'
import { Feed, type TestIds } from '@mntn-dev/ui-components'

type LinkProps<TRouteKey extends RouteKey> = TestIds & {
  target: LinkTarget<TRouteKey>
  t: TFunction<'activity'>
  onNavigate: (route: AnyRoute) => void
  currentUrl?: string
}

/** A url comparison that ignores query string parameters */
const areEquivelent = (url1: string, url2: string) =>
  new URL(url1, window.location.origin).pathname ===
  new URL(url2, window.location.origin).pathname

export const Link = <TRouteKey extends RouteKey>({
  target: { route, idTag },
  t,
  onNavigate,
  currentUrl,
}: LinkProps<TRouteKey>) => {
  const handleClick = () => onNavigate(route)

  return (
    // Don't show a Link if we're currently on it's URL
    !(currentUrl && areEquivelent(route.toRelativeUrl(), currentUrl)) && (
      <Feed.LinkButton onClick={handleClick}>
        {t(`link.${idTag}`)}
      </Feed.LinkButton>
    )
  )
}
