import { linkTargetResolver } from '@mntn-dev/app-routing'
import type {
  PostProductionReviewBrandFeedbackSubmittedReviewStatus,
  PostProductionReviewBrandFeedbackSubmittedRoundStatus,
} from '@mntn-dev/domain-types'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const PostProductionReviewBrandFeedbackSubmitted = ({
  activity: {
    activityId,
    person,
    details: {
      activityType,
      data: { reviewStatus, roundStatus, roundNumber, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'post_production_review_brand_feedback_submitted'>) => {
  const i18nKey = <Property extends 'summary' | 'status'>(
    property: Property
  ):
    | `${typeof activityType}.${Exclude<PostProductionReviewBrandFeedbackSubmittedReviewStatus, 'concepting'>}.${Property}`
    | `${typeof activityType}.${Extract<PostProductionReviewBrandFeedbackSubmittedReviewStatus, 'concepting'>}.${PostProductionReviewBrandFeedbackSubmittedRoundStatus}.${Property}` =>
    reviewStatus === 'resolved'
      ? `${activityType}.${reviewStatus}.${property}`
      : `${activityType}.${reviewStatus}.${roundStatus}.${property}`

  return (
    person && (
      <>
        {
          <Feed.Summary>
            <ActivityTrans
              t={t}
              i18nKey={i18nKey('summary')}
              values={{
                subject: person.displayName,
                roundNumber,
                project: target.project,
              }}
            />
          </Feed.Summary>
        }
        <Feed.Annotations>
          <Feed.Tag
            type={roundStatus === 'approved' ? 'success' : 'error'}
            dataTestId={`activity-feed-item-${activityId}-status-tag`}
            dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
          >
            {t(i18nKey('status'))}
          </Feed.Tag>
          <Link
            {...{
              target: linkTargetResolver.project({ target }),
              t,
              onNavigate,
              currentUrl,
              dataTestId: `activity-feed-item-${activityId}-view-project-link`,
              dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
            }}
          />
        </Feed.Annotations>
      </>
    )
  )
}
